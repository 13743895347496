import React, {useEffect} from "react";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import Header from "./views/Header";
import {Card, CardContent, Container} from "@material-ui/core";
import {authenticate} from "redux/actions/authActions"

import componentStyles from "argon/assets/theme/views/admin/buttons.js";
import componentStylesButtons from "argon/assets/theme/components/button.js";

import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
// import AgreementCard from "./views/agreements/AgreementCard";

import {connect} from "react-redux";
import Loader from "./views/common/Loader";
import UnAuthorized from "./views/common/UnAuthorized";
import ErrorPage from "./views/common/ErrorPage";
import CustomizedSnackbar from "./CustomizedSnackbar";
import Agreements from "./views/agreements/Agreements";
import AgreementRequest from "./views/agreements/AgreementRequest";

const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);

export function Home({authenticate, authState}) {
    const classes = { ...useStyles(), ...useStylesButtons() };

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('s');

    useEffect(  () => {
        document.body.style.backgroundColor = "white";
        authenticate(sessionId);
    }, [sessionId]);

    if (authState.isAuthorizing) {
        return <Loader></Loader>
    } else if (authState.isServerError) {
        return <ErrorPage></ErrorPage>
    } else if (!authState.isAuthorized) {
        return <UnAuthorized></UnAuthorized>
    }

    return (
        <BrowserRouter>
            <Container
                maxWidth={false}
                component={Box}
                classes={{ root: classes.containerRoot }}
            >
                <Header merchantId ={authState.merchantId}/>
                <CustomizedSnackbar />
                <Card classes={{ root: classes.cardRoot }} style={{height: "100%"}}>
                    <CardContent>
                        <Switch>
                            <Route path="/" exact={true}  component={Agreements} />} />
                            <Route path="/request/:id" component={AgreementRequest}/>
                            <Route path="/request" component={AgreementRequest}/>
                            <Redirect from="*" to="/"/>
                        </Switch>
                    </CardContent>
                </Card>
            </Container>
        </BrowserRouter>
    );
}

function mapStateToProps(state) {
    return {
        authState: state.auth
    };
}

const mapDispatchToProps = {
    authenticate
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);
