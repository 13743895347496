import React from "react";
import {useHistory} from "react-router-dom";
import AttachFile from "@material-ui/icons/Description";
import {Tooltip} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import axios from "axios";

const classes = {
    headerIcon: {
        width: "2rem",
        height: "100%",
        '&:hover': {
            cursor: 'grab'
        }
    }
}

export default function Header({merchantId}) {

    const [isOpen, setIsOpen] = React.useState(false);

    const history = useHistory();

    function downloadManual() {
        axios({
            url: process.env.REACT_APP_DD_MANUAL_URL,
            method: 'GET',
            responseType: 'application/pdf',
        }).then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'file.pdf');
            document.body.appendChild(fileLink);

            fileLink.click();
        });
    }

    return (
        <div className="d-flex justify-content-between mt-5">
            <div className="d-flex justify-content-start">
                <h1 style={{color: "#0b456e"}}>Direct Debit Agreements</h1>
            </div>
            <div className="d-flex justify-content-end">
                <Tooltip title="Direct debit portal manual">
                    <a href={process.env.REACT_APP_DD_MANUAL_URL} download={"direct-debit.pdf"} target="_blank" rel="noopener noreferrer">
                        <IconButton aria-label="view">
                            <Box
                                component={AttachFile}
                                width="2rem!important"
                                height="2rem!important"
                                color="#0b456e"
                                // onClick={downloadManual}
                            />
                        </IconButton>
                    </a>
                </Tooltip>
            </div>
        </div>
    );
}