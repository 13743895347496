import {useHistory} from "react-router-dom";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import {FormGroup, InputAdornment, OutlinedInput} from "@material-ui/core";
import Search from "@material-ui/icons/Search";
import AgreementCard from "./AgreementCard";
import {useEffect, useState} from "react";
import Loader from "../common/Loader";
import {loadAgreements} from "../../api/agreementAPI";
import {
    debounceTime,
    distinctUntilChanged,
    map,
    Subject,
    switchMap,
    from,
    startWith,
    catchError,
    of
} from "rxjs";

export function Agreements() {
    const history = useHistory();

    const [searchValue, setSearchValue] = useState("");
    const [isLoadingAgreements, setIsLoadingAgreements] = useState(true);
    const [agreements, setAgreements] = useState([]);
    const [agreementSubject, _] = useState(new Subject());

    useEffect(() => {
        const currentYear =  new Date().getFullYear();
        agreementSubject.pipe(
            startWith(""),
            debounceTime(500),
            distinctUntilChanged(),
            switchMap(key => {
                setIsLoadingAgreements(true);
                if (!key) {
                    key = currentYear;
                }
                return from(loadAgreements(key)).pipe(
                    catchError(err => of([])),
                    map(response => response.data)
                )
            })
        ).subscribe(data => {
            setAgreements(data);
            setIsLoadingAgreements(false);
        })

        return () => agreementSubject.unsubscribe();
    }, []);

    function routeToAddAgreement() {
        history.push("/request");
    }

    function routeToViewAgreement(id) {
        history.push(`/request/${id}`);
    }

    function onChangeSearchValue(event) {
        let value = event.target.value;
        setSearchValue(value);
        agreementSubject.next(value);
    }

    return (
        <>
            <div className="d-flex justify-content-between">
                <Button variant="contained" className="m-2" onClick={routeToAddAgreement}>
                    + Add Agreement Request
                </Button>
                <FormGroup>
                    <OutlinedInput
                        type="text"
                        placeholder="Search"
                        value={searchValue}
                        onChange={onChangeSearchValue}
                        startAdornment={
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        }
                    />
                </FormGroup>
            </div>

            {
                viewAgreements(isLoadingAgreements, agreements ? agreements : [], routeToViewAgreement)
            }
        </>
    );
}

function viewAgreements(isLoading, agreements, routeToViewAgreement) {
    if (isLoading) {
        return (
            <div className="mt-5">
                <Loader></Loader>
            </div>
        );
    } else {
        return (
            <div className="mt-4">
                <AgreementCard routeToViewAgreement={routeToViewAgreement} agreements={agreements}></AgreementCard>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

const mapDispatchToProps = {
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Agreements);