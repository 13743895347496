import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";

import componentStyles from "argon/assets/theme/views/admin/buttons.js";
import componentStylesButtons from "argon/assets/theme/components/button.js";
import componentStylesForms from "argon/assets/theme/components/forms.js";

import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import useAgreementForm from "../../hook/useAgreementForm";
import {connect} from "react-redux";
import {setSnackbar} from "../../redux/actions/snackbarAction";
import CustomerSearch from "./CustomerSearch";
import {useState} from "react";
import {customerInitModel} from "../../model/customerInitModel";
import {bankAccountInitModel} from "../../model/bankAccountInitModel";
import {saveAgreement} from "../../api/agreementAPI";
import {DatePicker} from "../common/DatePicker";
// import {KeyboardArrowDown} from "@material-ui/icons";

const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);
const useStylesForms = makeStyles(componentStylesForms);


export function AgreementRequest({authState, setSnackbar, props}) {
    const classes = { ...useStyles(), ...useStylesButtons(), ...useStylesForms() };

    let agreementId = props && props.match ? props.match.params.id : null;

    const [customer, setCustomer] = useState(customerInitModel);
    const [bankAccount, setBankAccount] = useState(bankAccountInitModel);
    const [isSaving, setIsSaving] = useState(false);
    const {setControlState, agreement, formValidityState} = useAgreementForm(agreementId);

    const history = useHistory();

    function backToAgreementsPage() {
        history.push("/agreements");
    }

    function createCustomer() {
        window.open(`${process.env.REACT_APP_CLIENT_FORM_URL}/create`, "_blank")
    }

    function editCustomer() {
        window.open(`not-impl/${customer.id}`, "_blank")
    }

    function isValidForm() {
        if (!customer.id) {
            setSnackbar(true, "error", "Please select a customer")
            return false;
        }
        const {requiredFields, isStartDateAfterEndDate} = formValidityState();
        if (!bankAccount.name || !bankAccount.no || !bankAccount.bsb) {
            requiredFields.push("Bank details")
        }
        if (!customer.first_name || !customer.last_name) {
            requiredFields.push("Customer details")
        }
        if (requiredFields.length > 0) {
            let requiredFieldsString = requiredFields.join(", ");
            setSnackbar(true, "error", `${requiredFieldsString} ${requiredFields.length === 1 ? 'is':'are'} required`);
            return false;
        } else if (isStartDateAfterEndDate) {
            setSnackbar(true, "error", `Start date can not be after end date`);
            return false;
        }
        return true;
    }

    async function submitRequest() {
        if (isValidForm()) {
            const agreementRequest = {...agreement};
            agreementRequest.customer = customer;
            agreementRequest.bank_account = bankAccount;
            agreementRequest.start_date = agreement.start_date.format("YYYY-MM-DD");
            agreementRequest.end_date = agreement.end_date.format("YYYY-MM-DD");
            agreementRequest.merchant_id = authState.merchantId;
            setIsSaving(true);
            try {
                await saveAgreement(agreementRequest);
                setSnackbar(true, 'success', 'Successfully saved!');
                setIsSaving(false);
                backToAgreementsPage();
            } catch (err) {
                setIsSaving(false);
            }
        }
    }

    return (
        <form>
            <Typography variant="h1" component="h2">
                {agreementId ? 'View Agreement' : 'Add Agreement Request'}
            </Typography>
            <Container
                maxWidth={false}
                component={Box}
                classes={{ root: classes.containerRoot }}
                className="mt-4"
            >
                {/*customer-search*/}
                <FormLabel required className="mb-3">Search Customer</FormLabel>
                <div className="row mb-4">
                    <CustomerSearch setCustomer={setCustomer} setBankAccount={setBankAccount} />
                    <Button variant="contained" className="col-12 col-sm-4 col-lg-2" style={{height: "max-content"}}
                        onClick={e => createCustomer()}>
                        + Add Customer
                    </Button>
                </div>

                {
                    customer.id ? (
                        <>
                            {/*Customer Details*/}
                            <FormLabel className="mb-3" required>Customer Details</FormLabel>
                            <div className="row">
                                <FormGroup className="col-md-7">
                                    <OutlinedInput
                                        fullWidth
                                        type="text"
                                        placeholder="Email"
                                        disabled
                                        value={customer.email}
                                    />
                                </FormGroup>
                            </div>
                            <div className="row">
                                <FormGroup className="col-md-4">
                                    <OutlinedInput
                                        fullWidth
                                        type="text"
                                        placeholder="First Name"
                                        value={customer.first_name}
                                        onChange={e => setCustomer({...customer, first_name: e.target.value})}
                                    />
                                </FormGroup>
                                <FormGroup className="col-md-4">
                                    <OutlinedInput
                                        fullWidth
                                        type="text"
                                        placeholder="Last Name"
                                        value={customer.last_name}
                                        onChange={e => setCustomer({...customer, last_name: e.target.value})}
                                    />
                                </FormGroup>
                            </div>

                            {/*Customer Address*/}
                            <FormLabel className="mb-3 mt-4">Customer Address</FormLabel>
                            <div className="row">
                                <FormGroup className="col-md-6">
                                    <OutlinedInput
                                        fullWidth
                                        type="text"
                                        placeholder="Address"
                                        disabled
                                        value={customer.address}
                                    />
                                </FormGroup>
                            </div>
                            <div className="row">
                                <FormGroup className="col-md-4">
                                    <OutlinedInput
                                        fullWidth
                                        type="text"
                                        placeholder="City"
                                        disabled
                                        value={customer.city}
                                    />
                                </FormGroup>
                                <FormGroup className="col-md-3">
                                    <OutlinedInput
                                        fullWidth
                                        type="text"
                                        placeholder="State"
                                        disabled
                                        value={customer.state}
                                    />
                                </FormGroup>
                                <FormGroup className="col-md-2">
                                    <OutlinedInput
                                        fullWidth
                                        type="text"
                                        placeholder="Post Code"
                                        disabled
                                        value={customer.post_code}
                                    />
                                </FormGroup>
                            </div>

                            {/*Bank Details*/}
                            <FormLabel className="mb-3 mt-4" required>Bank Account</FormLabel>
                            <div className="row">
                                <FormGroup className="col-md-5">
                                    <OutlinedInput
                                        fullWidth
                                        type="text"
                                        placeholder="Account Name"
                                        value={bankAccount.name}
                                        onChange={e => setBankAccount({...bankAccount, name: e.target.value})}
                                    />
                                </FormGroup>
                            </div>
                            <div className="row">
                                <FormGroup className="col-md-2">
                                    <OutlinedInput
                                        fullWidth
                                        type="text"
                                        placeholder="BSB"
                                        value={bankAccount.bsb}
                                        onChange={e => setBankAccount({...bankAccount, bsb: e.target.value})}
                                    />
                                </FormGroup>
                                <FormGroup className="col-md-4">
                                    <OutlinedInput
                                        fullWidth
                                        type="text"
                                        placeholder="Account No"
                                        value={bankAccount.no}
                                        onChange={e => setBankAccount({...bankAccount, no: e.target.value})}
                                    />
                                </FormGroup>
                            </div>
                            <div className="row">
                                <div className="d-flex justify-content-end mr-5 w-100">
                                    <Button variant="contained" style={{marginTop: "-2rem", width: "11rem"}}
                                            onClick={e => editCustomer()}>
                                        Edit Customer
                                    </Button>
                                </div>
                            </div>
                        </>
                    ) : null
                }


                {/*Title*/}
                <div className="row mt-2">
                    <div className="col-9">
                        <FormGroup>
                            <FormLabel required>Title</FormLabel>
                            <OutlinedInput
                                fullWidth
                                type="text"
                                placeholder="Title"
                                value={agreement.title}
                                onChange={e => setControlState("title", e)}
                            />
                        </FormGroup>
                    </div>
                </div>

                {/*Amount*/}
                <div className="row">
                    <FormGroup className="col-md-3 mr-2 mr-lg-7 mr-xl-9">
                        <FormLabel required>Amount</FormLabel>
                        <OutlinedInput
                            fullWidth
                            type="number"
                            placeholder="Amount"
                            value={agreement.amount}
                            onChange={e => setControlState("amount", e)}
                        />
                    </FormGroup>
                    <div className="col-md-5 mb-md-0 mb-7">
                        <div style={{position: "absolute"}}>
                            <FormLabel required style={{position: "relative", top: "0rem", display: "block"}}>Frequency</FormLabel>
                            <p className="mr-2" style={{position: "relative", display: "inline-block"}}>Every</p>
                            <FormGroup className="mr-2" style={{position: "relative", display: "inline-block", width: "4rem"}}>
                                <OutlinedInput
                                    fullWidth
                                    type="number"
                                    value={agreement.frequency.no_of_units}
                                    onChange={e => setControlState("frequency", e)}
                                />
                            </FormGroup>
                            <FormGroup style={{position: "relative", display: "inline-block", width: "8rem", top:"-0.6rem"}}>
                                <FormControl variant="outlined" fullWidth>
                                    <Select value={agreement.frequency.time_unit}
                                            onChange={e => setControlState("frequencyType", e)}>
                                        <MenuItem value={'DAY'}>Days</MenuItem>
                                        <MenuItem value={'WEEK'}>Weeks</MenuItem>
                                        <MenuItem value={'MONTH'}>Months</MenuItem>
                                        <MenuItem value={'YEAR'}>Years</MenuItem>
                                    </Select>
                                </FormControl>
                            </FormGroup>
                        </div>
                    </div>
                </div>

                {/*Time Period*/}
                <FormLabel required className="mt-1">Time Period</FormLabel>
                <div className="row">
                    <div className="col-md-4 mr-7">
                        <DatePicker placeholder="Start Date" setControlState={setControlState}
                                    value={agreement.start_date} name="startDate"/>
                    </div>
                    <div className="col-md-4">
                        <DatePicker placeholder="End Date" setControlState={setControlState}
                                    value={agreement.end_date} name="endDate"/>
                    </div>
                </div>

                {/*check boxes*/}
                <div className="row mt-1">
                    <div className="col-md-12">
                        <Switch value={agreement.skip_consent}
                                onChange={e => setControlState("skipConsent", e)} className="mr-3"/>
                        <FormLabel>Already have a signed agreement with the customer</FormLabel>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-12">
                        <Switch value={agreement.disable_schedule_payments}
                                onChange={e => setControlState("disableScheduledPayments", e)} className="mr-3"/>
                        <FormLabel>Disable scheduled payments</FormLabel>
                    </div>
                </div>

                {/*footer buttons*/}
                <div className="float-right">
                    <Button variant="contained" className="mr-2" classes={{root: classes.buttonContainedWarning,}}
                            onClick={backToAgreementsPage}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={submitRequest} disabled={isSaving}>
                        {isSaving ? 'Saving...' : 'Send Request'}
                    </Button>
                </div>

            </Container>

        </form>
    );
}

function mapStateToProps(state, props) {
    return {
        authState: state.auth,
        props
    };
}

const mapDispatchToProps = {
    setSnackbar
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AgreementRequest);